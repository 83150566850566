define(['core/jed!core/settings/nls/settings'], function(i18n) {
return {
	menuSettingsTitle: i18n.gettext("Settings"),
	dialogSettingsTitle: i18n.gettext("Settings"),
	tabPasswordTitle: i18n.gettext("Password"),
	currentPassword: i18n.gettext("Current password"),
	password: i18n.gettext("New password"),
	passwordRepeat: i18n.gettext("Repeat password"),
	changePasswordLabel: i18n.gettext("Change your password"),
	passwordMismatchError: i18n.gettext("Passwords don't match"),
	passwordTooShort: i18n.gettext("New password is too short"),
	serverErrorPassword: i18n.gettext("Couldn't change password"),
	serverErrorLanguage: i18n.gettext("Couldn't change language"),
	tabLanguageTitle: i18n.gettext("Language"),
	pwdChangedOk: i18n.gettext("Password changed successful"),
	language: i18n.gettext("Language"),
	changeLanguageLabel: i18n.gettext("Change system language"),
	languageNotice: i18n.gettext("* Language will change only after restart"),
	restartLabel: i18n.gettext("Apply and restart"),
	langChangedOk: i18n.gettext("Language changed successful"),
	tabApiTitle: i18n.gettext("API"),
	apiUserLabel: i18n.gettext("API key for user ${0}"),
	active: i18n.gettext("active"),
	removed: i18n.gettext("removed"),
	create: i18n.gettext("Create"),
	remove: i18n.gettext("Remove"),
	query: i18n.gettext("Query"),
	balloonSettingsTitle: i18n.gettext("Object on map"),
	openByClick: i18n.gettext("Open by click"),
	openByHover: i18n.gettext("Open by hover"),
	infoAboutObject: i18n.gettext("Information about the object on the map"),
	settingsChangedOk: i18n.gettext("Settings changed successful"),
	serverErrorBalloon: i18n.gettext("Couldn't change settings"),
	balloon: {
		icon: i18n.gettext("Icon"),
		tooltip: i18n.gettext('Names for "Show All"'),
		message: i18n.gettext("Last message"),
		position: i18n.gettext("Position"),
		track: i18n.gettext("Draw track"),
		fence: i18n.gettext("Geofence"),
		speed: i18n.gettext("Speed"),
		hours: i18n.gettext("Moto hours"),
		height: i18n.gettext("Height"),
		mileage: i18n.gettext("Mileage"),
		satellites: i18n.gettext("Satelites"),
		sensors: i18n.gettext("Sensors"),
		tracker: i18n.gettext("Tracker")
	},
	chart: {
		title: i18n.gettext("Charts"),
		text: i18n.gettext("Number of charts on the screen")
	},
	notificationTitle: i18n.gettext("Notifications"),
	notificationHeader: i18n.gettext("On-line notifications"),
	openListOnNew: i18n.gettext("Open list when new notifications arrive"),
	closeListOnEmpty: i18n.gettext("Close list when all notifications are removed"),
	playSoundOnNew: i18n.gettext("Play sound when new notifications arrive"),
	report: {
		title: i18n.gettext("Reports"),
		reportsAvailableByDefault: i18n.gettext("Reports available by default")
	},
	chat: {
		title: i18n.gettext("Chat"),
		subtitle: i18n.gettext("Chat"),
		pollUnreadCountLabel: i18n.gettext("Enable polling of the number of new messages"),
	},
	map: {
		userIconOnMap: i18n.gettext("Show user icons on map"),
	},
}
});
