import wapi from 'webapi/rpc/web'
import config from 'webapi/rpc/config'
import { DispatchObject } from './DispatchObject'
import { PromiseIterator } from './PromiseIterator'
import Repository from './Repository'
import { Vehicle } from '../dispatch/Vehicle'
import { AsyncLoaders } from './AsyncLoaders'

export class Tracker extends DispatchObject {
	static entityName = 'tracker'

	_parentConstructor = Vehicle

	getDeviceIdent() {
		return this.get('deviceident')
	}

	getLicenseKey() {
		return this.get('licensekey')
	}

	getConfig() {
		return this.get('config')
	}

	setConfig(config) {
		return this.set('config', config)
	}

	getPhone() {
		return this.get('phonenumber')
	}

	setPhone(phone) {
		return this.set('phonenumber', phone)
	}

	getImei() {
		return this.get('imei')
	}

	setImei(value) {
		return this.set('imei', value)
	}

	getHardwareId() {
		return this.get('hardware_id')
	}

	setHardwareId(value) {
		return this.set('hardware_id', value)
	}

	getType() {
		return this.get('kind')
	}

	setType(value) {
		return this.set('kind', value)
	}

	getVendor() {
		return this.get('vendor')
	}

	setVendor(vendor) {
		return this.set('vendor', vendor)
	}

	getModel() {
		return this.get('model')
	}

	setModel(model) {
		return this.set('model', model)
	}

	getVehicleId() {
		return this.get('vehicleid')
	}

	setVehicleId(id) {
		return this.set('vehicleid', id)
	}

	getGroupId() {
		return this.get('groupid')
	}

	setGroupId(id) {
		return this.set('groupid', id)
	}

	getConfigStatus() {
		return this.get('configstatus')
	}

	setConfigStatus(/** TrackerStatus.Status field */ newStatus) {
		return this.set('configstatus', newStatus)
	}

	getLoadCoordinates() {
		return Boolean(this.get('load_coordinates'))
	}

	setLoadCoordinates(value) {
		return this.set('load_coordinates', Boolean(value))
	}

	hasStatusJson() {
		return 'status_json' in this.properties
	}

	getStatusJson() {
		try {
			const value = this.get('status_json') || null
			return (typeof value === 'string') ? JSON.parse(value) : null
		} catch (e) {
			return null
		}
	}

	getSourceStatusJson() {
		try {
			const value = this.get('source_status_json') || null
			return (typeof value === 'string') ? JSON.parse(value) : null
		} catch (e) {
			return null
		}
	}

	getStatusHistory(fullHistory) {
		const newObjectStatus = {
			type: IdentificationStatus.NewObject,
			timestamp: this.getFromTimestamp() / 1000 | 0,
		}

		const statusChanges = this.getStatusJson() ?? []
		if (!fullHistory) return [ newObjectStatus, ...statusChanges ]

		const history = [newObjectStatus]
		const sourceStatusChanges = this.getSourceStatusJson() ?? []

		const statusList = [ ...statusChanges, ...sourceStatusChanges ]
			.sort((a, b) => a.timestamp - b.timestamp)

		statusList.forEach(({ type, wrongSource, timestamp }) => {
			if (type != null) {
				history.push({ type, timestamp })
			} else if (wrongSource) {
				history.push({ type: IdentificationStatus.IncorrectSource, timestamp })
			} else {
				history.push({ type: IdentificationStatus.ReturnToCorrectSource, timestamp })
			}
		})

		return history
	}

	isWrongSource() {
		return Boolean(this.get('wrong_source'))
	}

	setStatusJson(v) {
		return this.set('status_json', v !== null ? JSON.stringify(v) : null)
	}

	getFromTimestamp() {
		return new Date(this._fromTimestampISO ? this.get('from_timestamp') : (1000 * this.get('from_timestamp')))
	}

	setFromTimestamp(value) {
		// FIXME there is mixin for this strange operations in branch feature/56714/tasks
		this._fromTimestampISO = (value && value.toISOString && true)
		return this.set(
			'from_timestamp',
			((value && value.toISOString) ? value.toISOString() : value),
			(value === '' ? () => false : null),
		)
	}

	getToTimestamp() {
		return new Date(this._toTimestampISO ? this.get('to_timestamp') : (1000 * this.get('to_timestamp')))
	}

	setToTimestamp(value) {
		// FIXME there is mixin for this strange operations in branch feature/56714/tasks
		this._toTimestampISO = (value && value.toISOString && true)
		return this.set(
			'to_timestamp',
			((value && value.toISOString) ? value.toISOString() : value),
			(value === '' ? () => false : null),
		)
	}

	getParameters() {
		try {
			return JSON.parse(this.get('parameters_json') || '{}')
		} catch (e) {
			console.error('wrong Tracker.parameters_json:', this.get('parameters_json'))
			return {} // or null?
		}
	}

	setParameters(p) {
		return this.set('parameters_json', JSON.stringify(p))
	}

	getSensorList() {
		const { sensorList } = this.getParameters()
		return sensorList ?? []
	}

	setSensorList(sensorList) {
		const params = this.getParameters()
		return this.setParameters({ ...params, sensorList })
	}

	getVehicleName() {
		return this.get('vehicle_name')
	}
	getCreationTimestamp() {
		return this.get('create_timestamp')
	}
	getLastChangeTimestamp() {
		return this.get('last_change_timestamp')
	}
	getFirstConnectionTimestamp() {
		return this.get('first_connect_timestamp')
	}
	getMaxReceivedTimestamp() {
		return this.get('max_received_timestamp')
	}

	isMarkedAsDeleted() {
		return Boolean(this.get('deleted'))
	}

	//
	// Tracker.getSupportedCommands
	//
	// returns Promise that resolves with an array of next objects:
	// {
	//   command: "<type>",
	//   parameters: {"<param0key>": "<param0value>", ....},
	//   results: ["<result0>", ...]
	// }
	// See wiki for details
	getSupportedCommands() {
		const params = [this.getType(), this.getVendor(), this.getModel()]

		return wapi.send('tracker.supportedCommands', params).then((data) => {
			try {
				return JSON.parse(data)
			} catch (e) {
				return null
			}
		})
	}

	copy(transaction) {
		const result = super.copy(transaction)
		result.setFromTimestamp(this.getFromTimestamp())
		result.setToTimestamp(null)
		result.setStatusJson([])
		result.set('licensekey', '')
		result.set('deviceident', '')
		return result
	}

	detach() {
		this.setToTimestamp('')
		this.setVehicleId('')
	}
}

function fromJsonRpc(properties) {
	return new Tracker(properties && properties.parent, properties)
}

export function editTrackers(idList, params) {
	return wapi.send('tracker.edit', [idList, params])
}
Tracker.editTrackers = editTrackers

export function get(recursive, group) {
	return new PromiseIterator(
		group.getIds().then(Repository.listGetter.tracker.bind(null, recursive)),
		fromJsonRpc,
	)
}
Tracker.get = get

export async function getById(id) {
	const props = await Repository.byIdGetter.tracker(id)
	return fromJsonRpc(props)
}
Tracker.getById = getById

export async function getByIdList(idList) {
	const list = await Repository.byIdListGetter.tracker(idList)
	return list.map(fromJsonRpc)
}
Tracker.getByIdList = getByIdList

export async function getTrackerBundle() {
	return wapi.send('tracker.getTrackerBundleJson')
}
Tracker.getTrackerBundle = getTrackerBundle

export async function getDescriptor(kind) {
	if (kind) return wapi.send('tracker.descriptor', [kind])
	throw new Error('Kind is empty.')
}
Tracker.getDescriptor = getDescriptor

export async function getDescriptorIds() {
	return wapi.send('tracker.descriptorIds')
}
Tracker.getDescriptorIds = getDescriptorIds

export function getNextStatus(status) {
	switch(status) {
		case IdentificationStatus.NewObject: return IdentificationStatus.Installed
		case IdentificationStatus.Installed: return IdentificationStatus.Verified
		case IdentificationStatus.Verified: return IdentificationStatus.Identified
		default: return null
	}
}
Tracker.getNextStatus = getNextStatus

export function canChangeStatusTo(tracker, status) {
	let currentStatus = tracker.getStatusHistory().at(-1).type

	while (currentStatus != null && currentStatus < status) {
		currentStatus = getNextStatus(currentStatus)
	}
	return currentStatus === status
}
Tracker.canChangeStatusTo = canChangeStatusTo

export function nextStatus(tracker) {
	const statusHistory = tracker.getStatusHistory()
	const nextStatus = getNextStatus(statusHistory[statusHistory.length - 1].type)
	statusHistory.push({
		type: nextStatus,
		timestamp: Math.floor(Date.now() / 1000),
	})
	return tracker.setStatusJson(statusHistory.slice(1)) // NOTE: ignore NewObject status
}
Tracker.nextStatus = nextStatus

export function getStatus(tracker) {
	return tracker.isWrongSource()
		? { type: IdentificationStatus.IncorrectSource }
		: tracker.getStatusHistory().at(-1)
}
Tracker.getStatus = getStatus

export function setStatus(tracker, status) {
	if (!canChangeStatusTo(tracker, status)) throw new Error(`Can't change status`)

	let currentStatus = tracker.getStatusHistory().at(-1).type

	while (currentStatus !== status) {
		nextStatus(tracker)
		currentStatus = getNextStatus(currentStatus)
	}
}
Tracker.setStatus = setStatus

export function getAsyncLoaders(group, recursive) {
	return new AsyncLoaders({
		getExtraArguments: () => AsyncLoaders.extraArguments.groupRecursive(group, recursive),
		requestGet: 'tracker.getPortion',
		countGet: 'tracker.count',
	})
}
Tracker.getAsyncLoaders = getAsyncLoaders

wapi.cacheRegister('tracker.getPortion', {
	getPath: (params) => {
		return params.map((value) => JSON.stringify(value))
	},
	maxSize: 10,
	expire: config.portionPollingInterval,
})
Repository.registerInvalidator('tracker', () => wapi.cacheInvalidate('tracker.getPortion'))

export async function getTrackersCount(args, extraArgs) {
	let allArgs = ['filtered', args]
	if (extraArgs) allArgs = allArgs.concat(extraArgs)
	return wapi.send('tracker.count', allArgs)
}
Tracker.getTrackersCount = getTrackersCount

wapi.cacheRegister('tracker.count', {
	getPath: ([level, args, extra]) => {
		delete args.limit
		delete args.offset
		return [level, JSON.stringify(args), JSON.stringify(extra)].filter(Boolean)
	},
	maxSize: 10,
	expire: config.portionPollingInterval,
})

function _invalidateCount() {
	wapi.cacheInvalidate('tracker.count')
}

Repository.registerInvalidator('tracker', _invalidateCount)

export function getTrackerHistory(tracker) {
	return wapi.send('tracker.getHistoryById', [
		tracker.getDeviceIdent(),
		tracker.getCreationTimestamp(),
	])
}
Tracker.getTrackerHistory = getTrackerHistory

export function getByVehicleId(vehicleId) {
	return wapi.send('tracker.getByVehicleId', [vehicleId])
}
Tracker.getByVehicleId = getByVehicleId

/**
 * @private
 */
function _invalidate() {
	wapi.cacheInvalidate('tracker.getTrackerBundleJson')
}

export async function getConnectionSettings(parserName) {
	return wapi.send('tracker.getTrackerConnectionSettings', [parserName])
}
Tracker.getConnectionSettings = getConnectionSettings

Repository.registerListGetter('tracker', (recursive, groups) => wapi.send('tracker.get', { recursive, groups }))
Repository.registerByIdGetter('tracker', (id) => wapi.send('tracker.getById', [id]))
Repository.registerByIdListGetter('tracker', (idList) => wapi.send('tracker.getByIdList', [idList]))
Repository.registerInvalidator('tracker', _invalidate.bind(Tracker))

wapi.cacheRegister('tracker.getTrackerBundleJson', {
	getPath: () => [],
	maxSize: 1,
})

export const FilterColumns = {
	IMEI: 'imei',
	Phone: 'phonenumber',
	PhoneHistory: 'phoneHistory',
	MonitoringObject: 'vehicle_name',
	MaxReceivedTimestamp: 'max_received_timestamp',
}
Tracker.FilterColumns = FilterColumns

export const ConfigStatus = {
	Unknown: 0,
	ConfigurationInProgress: 1,
	ConfigurationSent: 2,
	Configured: 3,
	ConfigurationFailed: 4,
	ConfigurationWillBeSent: 0xFF, // NOTE: Fake status for objectWizard
}
Tracker.ConfigStatus = ConfigStatus

export const IdentificationStatus = {
	IncorrectSource: -3,
	ReturnToCorrectSource: -2,
	NewObject: -1,
	Installed: 0,
	Verified: 1,
	Identified: 2,
	RequiredAutoIdentification: 3,
	AutoIdentificationDone: 4,
}
Tracker.IdentificationStatus = IdentificationStatus
